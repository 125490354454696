.home__hero--section {
  color: white;
  padding: 160px 0;
}

.home__hero-row {
  align-items: center;
}

.row {
  display: flex;
  margin-bottom: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
}

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.home__text--text-wrapper {
  max-width: 540px;
  padding-top: 0;
  padding-top: 60px;
}

.top__line {
  color: #b20000;
  font-size: 25px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.heading {
  margin-bottom: 16px;
  font-size: 84px;
  line-height: 1.1;
  font-weight: 600;
  color: #f7f8fa;
  font-weight: bolder;
}

.dark {
  color: #444442;
}

.darkBg {
  background-color: #eeecdf;
}

.home__hero--subtitle {
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 27px;
  line-height: 32px;
}

.home__hero--img-wrapper {
  max-width: 555px;
}

.home__hero--img {
  max-width: 95%;
  transform: translateY(-20px);
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
}

img {
  border: 0;
  max-width: 10 0%;
  vertical-align: middle;
  display: inline-block;
  transform: translateY(65px);
}

li {
  font-size: 18px;
}

a {
  color: black;
}

.big {
   font-size: 24px;
}

@media screen and (max-width: 991px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 768px) {
  .home__hero--text-wrapper {
    padding-bottom: 65px;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media screen and (max-width: 450px) {
  .heading {
    font-size: 65px;
    margin-bottom: 30px;
  }

  .top__line {
    margin-top: -110px;
  }

  .home__hero--img {
    margin-top: -40px;
    margin-bottom: -25px;
  }
}

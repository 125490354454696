.pricing__section {
  padding: 100px 0 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fafafa;
}

.pricing__container--card {
  background: linear-gradient(45deg, #444442 0%, #928b85 100%);
  box-shadow: 0 19px 19px black;
  width: 280px;
  height: 500px;
  text-decoration: none;
  border-radius: 4px;
}

.pricing__container--card:nth-child(2) {
  transform: scale(1.05);
  background: #242424;
  margin: 0 24px;
}

.pricing__container--card:hover {
  transform: scale(1.06);
  transition: all 0.3s ease-out;
  color: #1c2237;
}

.pricing__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing__wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 auto;
}

.pricing__heading {
  color: #1c2237;
  margin-bottom: 24px;
}

.pricing__container--cardInfo {
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: 24px;
  align-items: center;
  color: #eeecdf;
}

.pricing__container--cardInfo h1 {
  
  font-size: 28px;
}

.pricing__container--cardInfo h3 {
  margin-bottom: 5px;
  font-size: 24px;
}

.pricing__container--cardInfo h4 {
  font-size: 30px;
  margin-top: 12px;
}

.pricing__container--features {
  margin: 16px 0 32px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricing__container--features li {
  margin-bottom: 10px;
}

.icon {
  margin: 24px 0;
}

@media screen and (max-width: 960px) {
  .pricing__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .pricing__container--card {
    width: 100%;
  }

  .pricing__wrapper {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pricing__container--card:nth-child(2) {
    transform: scale(1);
    background: #242424;
    margin: 24px;
  }

  .pricing__container--card :hover {
    transform: none;
  }
}

.navbar {
  background: #cfcfc3;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar__container {
  display: flex;
  justify-content: space-between;
  height: 80px;
}

.container {
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.navbar__logo {
  color: #eeecdf;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  height: 80px;
  transform: translateY(15px);
  align-items: center;
}

.navbar__icon {
  margin-right: 0.5rem;
}

.nav__menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: end;
}

.nav__item {
  height: 80px;
  border-bottom: 2px solid transparent;
}

.nav__item:hover {
  border-bottom: 7px solid #b20000;
}

.nav__links {
  color: #242424;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-weight: bolder;
}

.fa__bars {
  color: #eeecdf;
}

.menu__icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav__menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav__menu.active {
    background: #eeecdf;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
    height: 300px;
  }

  .nav__links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav__links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav__item:hover {
    border: none;
  }

  .nav__item {
    width: 100%;
  }

  .navbar__logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 15%);
  }

  .menu__icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa__times {
    color: #fff;
    font-size: 2rem;
  }

  .nav__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
}

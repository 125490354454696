:root {
  --primary: #b20000;
}

.btn {
  padding: 16px 28px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.btn__primary {
  background-color: #b20000;
  color: #242424;
  border: 1px solid #b20000;
}

.btn__outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #b20000;
  transition: all 0.3s ease-out;
}

.btn__medium {
  padding: 14px 40px;
  font-size: 20px;
  border-radius: 16px;
}

.btn__large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn__mobile {
  text-align: center;
  border-radius: 4px;
  width: 80%;
  text-decoration: none;
  font-size: 24px;
  background-color: transparent;
  color: #b20000;
  border: 14px 20px;
  border: 1px solid #b20000;
  transition: all 0.3s ease-out;
}

.btn__wide {
  padding: 10px 64px;
  font-size: 20px;
}

.btn__large:hover,
.btn__medium:hover,
.btn__mobile:hover {
  transition: all 0.3s ease-out;
  background: #b20000;
  color: #242424;
}

.btn__wide:hover {
  color: #fff;
  background-color: #f00946;
  transform: all 0.3s ease-out;
}

.blue {
  background-color: #056a54;
  color: white;
  border: none;
}

.black {
  background: #242424;
  color: white;
  border: none;
}
.primary {
  background-color: #b20000;
  color: white;
  border: none;
}
.primary:hover {
  background-color: #db0808;
  color: white;
  border: none;
}
.green {
  background-color: #25ce4a;
  color: white;
  border: none;
}

.btn__link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
}
